<template>
  <div>
    <el-tabs type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息">
        <Basicinformation
          :match_id="match_id"
          :match_type="match_type"
          :stage_id="stage_id"
          :view_type="view_type"
        ></Basicinformation>
      </el-tab-pane>
      <el-tab-pane label="预测" >
        <prediction
         ref="predictionRef"
          :match_id="match_id"
          :match_type="match_type"
          :season_id="season_id"
        ></prediction>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Basicinformation from "./Basicinformation.vue";
import prediction from "./prediction.vue";
export default {
  name: "manager_qualifiter1",
  components: {
    Basicinformation,
    prediction,
  },
  props: {
    season_id: {
      type: String,
    },
    match_id: {
      type: String,
    },
    match_type: {
      type: String,
    },
    stage_id: {
      type: String,
    },
    view_type: {
      type: String,
    },
  },
  methods:{
     handleClick(tab, event) {
      if (tab.label === '预测') {
          this.$refs.predictionRef.handleQuery();
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__item {
  width: 100px;
  text-align: center;
}
</style>